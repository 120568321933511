import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from '~/components/atoms/Button';
import CheckboxField from '~/components/atoms/CheckboxField';
import InputField from '~/components/atoms/InputField';
import useFormSubmit from '~/hooks/useFormSubmit';
import SelectField from '../../components/atoms/SelectField';

const VisuallyHiddenField = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  pointer-events: none;
`;

const validationSchema = Yup.object({
    name: Yup.string(),
    property_owner_first_name: Yup.string().required('Required'),
    property_owner_last_name: Yup.string().required('Required'),
    property_owner_email: Yup.string().email('Invalid email address').required('Required'),
    property_owner_phone: Yup.string().required('Required'),
    property_city: Yup.string().required('Required'),
    property_postCode: Yup.string(),
    referrer_fist_name: Yup.string().required('Required'),
    referrer_last_name: Yup.string().required('Required'),
    referrer_email: Yup.string().email('Invalid email address').required('Required'),
    referrer_phone: Yup.string(),
    message: Yup.string(),
    privacy_policy: Yup.boolean().oneOf([true], 'This field must be checked'),
});


export default function ReferralForm() {

    const [submitting, setSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { post } = useFormSubmit({
        portalId: '7026924',
        formId: '4ae5b622-df1d-44b5-8539-f82034e2df36',
        setIsSubmitted,
    });

    return (
        <div className={`px-8 py-12 md:py-16 md:px-20 bg-white relative border border-red-500 bg-white border border-[#EFEEEA]`}>
            <Formik
                initialValues={{
                    name: '',
                    // referrer_name: '',
                    // referrer_email: '',
                    // referrer_phone: '',
                    // property_owner_name: '',
                    // property_owner_email: '',
                    // property_owner_phone: '',
                    // privacy_policy: false,
                    property_owner_first_name: '',
                    property_owner_last_name: '',
                    property_owner_email: '',
                    property_owner_phone: '',
                    property_city: '',
                    property_postCode: '',
                    referrer_fist_name: '',
                    referrer_last_name: '',
                    referrer_email: '',
                    referrer_phone: '',
                    message: '',
                    privacy_policy: false,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikProps) => {

                    setSubmitting(true);

                    //Spam validation
                    if (values.name) {
                        setSubmitting(false);
                        formikProps.resetForm();
                    }
                    else {

                        delete values['name'];
                        delete values['privacy_policy'];

                        post(values).then(() => {
                            setSubmitting(false);
                            setIsSubmitted(true);
                            formikProps.resetForm();
                        });

                    }
                }}
            >
                {() => (
                    <Form id="referral-form" className="grid grid-cols-12 gap-y-8">
                        {/** Spam validation */}
                        <VisuallyHiddenField>
                            <InputField
                                divclassname="col-span-12 "
                                name={'name'}
                                type="text"
                                label={'Name'}
                            />
                        </VisuallyHiddenField>
                        {[
                            { name: 'property_owner_first_name', label: 'Owner First Name:' },
                            { name: 'property_owner_last_name', label: 'Owner Last Name:' },
                            { name: 'property_owner_email', label: 'Owner Email:' },
                            { name: 'property_owner_phone', label: 'Owner Phone Number:' },
                            { name: 'property_postCode', label: 'Property Postcode:' },
                            { name: 'referrer_fist_name', label: 'Your First name:' },
                            { name: 'referrer_last_name', label: 'Your Last name:' },
                            { name: 'referrer_email', label: 'Your Email:' },
                            { name: 'referrer_phone', label: 'Your Phone Number (inc. + international code):' },
                            { name: 'message', label: 'Message:' },
                            // { name: 'referrer_name', label: 'Your name:' },
                            // { name: 'referrer_email', label: 'Your email:' },
                            // { name: 'referrer_phone', label: 'Your contact number:' },
                            // { name: 'property_owner_name', label: "Your friend/family's name:" },
                            // { name: 'property_owner_email', label: "Your friend/family's email:" },
                            // { name: 'property_owner_phone', label: "Your friend/family's contact number:" },
                        ].map((field, index) => (
                            <InputField
                                divclassname="col-span-12 "
                                name={field.name}
                                type="text"
                                label={field.label}
                                key={index}
                            />
                        ))}
                 
                        <SelectField
                            divclassname="col-span-12 md:col-span-6"
                            name="property_city"
                            label="Property City"
                            options={[
                                { label: 'London', value: 'london' },
                                { label: 'Paris', value: 'paris' },
                            ]}
                        />
                        <CheckboxField
                            divclassname="col-span-12"
                            name="privacy_policy"
                            label={'By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>'}
                        />
                        <div className="col-span-12">
                            <div className="w-full">
                                <Button
                                    title={'Refer'}
                                    className={`w-full`}
                                    type="submit"
                                    submitting={submitting}
                                />
                            </div>
                            {isSubmitted && (
                                <p className='w-full my-6 text-center'>{"Your referral request was correctly sent!"}</p>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}